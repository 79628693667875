import calendarImage from './calendar-img.svg';

const evenst_list = [
  {
    day: 5,
    category: 'live',
    title: 'Creando una web desde cero con HTML y CSS',
    description: 'Aprende a construir una web básica desde cero.',
    date: '06/03/2025', // Aquí separamos la fecha y la hora
    time: '9:00pm GMT-5',
    image: calendarImage,
  },
  {
    day: 15,
    category: 'presencial',
    title: 'Club de Lectura - Eloquent Javascript',
    description: 'Discusión sobre el libro Eloquent Javascript.',
    date: '15/03/2025',
    time: '10:00pm GMT-5',
    image: calendarImage,
  },
  {
    day: 22,
    category: 'presencial',
    title: 'Workshop: React Hooks',
    description: 'Aprende a usar los hooks de React en proyectos reales.',
    date: '22/03/2025',
    time: '6:00pm GMT-5',
    image: calendarImage,
  },

  {
    day: 5,
    category: 'live',
    title: 'Creando una web desde cero con HTML y CSS',
    description: 'Aprende a construir una web básica desde cero.',
    date: '06/03/2025', // Aquí separamos la fecha y la hora
    time: '9:00pm GMT-5',
    image: calendarImage,
  },
  {
    day: 15,
    category: 'presencial',
    title: 'Club de Lectura - Eloquent Javascript',
    description: 'Discusión sobre el libro Eloquent Javascript.',
    date: '15/03/2025',
    time: '10:00pm GMT-5',
    image: calendarImage,
  },
  {
    day: 22,
    category: 'presencial',
    title: 'Workshop: React Hooks',
    description: 'Aprende a usar los hooks de React en proyectos reales.',
    date: '22/03/2025',
    time: '6:00pm GMT-5',
    image: calendarImage,
  },


  {
    day: 5,
    category: 'live',
    title: 'Creando una web desde cero con HTML y CSS',
    description: 'Aprende a construir una web básica desde cero.',
    date: '06/03/2025', // Aquí separamos la fecha y la hora
    time: '9:00pm GMT-5',
    image: calendarImage,
  },
  {
    day: 15,
    category: 'presencial',
    title: 'Club de Lectura - Eloquent Javascript',
    description: 'Discusión sobre el libro Eloquent Javascript.',
    date: '15/03/2025',
    time: '10:00pm GMT-5',
    image: calendarImage,
  },
  {
    day: 22,
    category: 'presencial',
    title: 'Workshop: React Hooks',
    description: 'Aprende a usar los hooks de React en proyectos reales.',
    date: '22/03/2025',
    time: '6:00pm GMT-5',
    image: calendarImage,
  },



  {
    day: 5,
    category: 'live',
    title: 'Creando una web desde cero con HTML y CSS',
    description: 'Aprende a construir una web básica desde cero.',
    date: '06/03/2025', // Aquí separamos la fecha y la hora
    time: '9:00pm GMT-5',
    image: calendarImage,
  },
  {
    day: 15,
    category: 'presencial',
    title: 'Club de Lectura - Eloquent Javascript',
    description: 'Discusión sobre el libro Eloquent Javascript.',
    date: '15/03/2025',
    time: '10:00pm GMT-5',
    image: calendarImage,
  },
  {
    day: 22,
    category: 'presencial',
    title: 'Workshop: React Hooks',
    description: 'Aprende a usar los hooks de React en proyectos reales.',
    date: '22/03/2025',
    time: '6:00pm GMT-5',
    image: calendarImage,
  },


  {
    day: 5,
    category: 'live',
    title: 'Creando una web desde cero con HTML y CSS',
    description: 'Aprende a construir una web básica desde cero.',
    date: '06/03/2025', // Aquí separamos la fecha y la hora
    time: '9:00pm GMT-5',
    image: calendarImage,
  },
  {
    day: 15,
    category: 'presencial',
    title: 'Club de Lectura - Eloquent Javascript',
    description: 'Discusión sobre el libro Eloquent Javascript.',
    date: '15/03/2025',
    time: '10:00pm GMT-5',
    image: calendarImage,
  },
  {
    day: 22,
    category: 'presencial',
    title: 'Workshop: React Hooks',
    description: 'Aprende a usar los hooks de React en proyectos reales.',
    date: '22/03/2025',
    time: '6:00pm GMT-5',
    image: calendarImage,
  },



  {
    day: 5,
    category: 'live',
    title: 'Creando una web desde cero con HTML y CSS',
    description: 'Aprende a construir una web básica desde cero.',
    date: '06/03/2025', // Aquí separamos la fecha y la hora
    time: '9:00pm GMT-5',
    image: calendarImage,
  },
  {
    day: 15,
    category: 'presencial',
    title: 'Club de Lectura - Eloquent Javascript',
    description: 'Discusión sobre el libro Eloquent Javascript.',
    date: '15/03/2025',
    time: '10:00pm GMT-5',
    image: calendarImage,
  },
  {
    day: 22,
    category: 'presencial',
    title: 'Workshop: React Hooks',
    description: 'Aprende a usar los hooks de React en proyectos reales.',
    date: '22/03/2025',
    time: '6:00pm GMT-5',
    image: calendarImage,
  },
];

export default evenst_list;