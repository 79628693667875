import React, { Suspense } from 'react'
import Skeleton from 'react-loading-skeleton'
import headerImage from '../../components/commons/Testimony/images/aboutUs.svg'
import Hero from '../../components/commons/Hero/Hero'
const CardTestimony = React.lazy(() =>
  import('../../components/commons/Testimony/Card_Testimony')
)

const Testimony = () => {
  return (
    <div>
      <Hero title={`Nuestros testimonios`} image={headerImage} />
      <Suspense
        fallback={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Skeleton count={2} width={600} height={300} />
          </div>
        }
      >
        <CardTestimony />
      </Suspense>
    </div>
  )
}

export default Testimony
