import React from 'react'

const RecommendedServiceCard = (props) => {
  const { image, description, orientation_image } = props.course

  return (
    <div className='recommended-cards__service' id='service'>
      <div className='recommended-cards__service-image'>
        <img src={image} alt='' />
      </div>
      <div className='recommended__card-description'>
        <span>{description}</span>
      </div>
    </div>
  )
}

export default RecommendedServiceCard
