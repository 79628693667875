import React from "react";
import { useSelector } from "react-redux";
import CardSkeleton from "../Skeletons/CardSkeleton";
import ObjetivoCard from "./ObjetivoCard";

import imagen1 from "./images/vision.svg"
import imagen2 from "./images/mision.svg"
import "./style.css";

const Objetivo = () => {
  const cursosDisponibles = {
    objetivos: [
      //status, image, title, description, link, releaseDate
      {
        image: imagen1,
        title: 'visión',
        description:
          'Equipo de educadores  con permanente actualización y   perfeccionamiento en el ámbito educativo, líderes en la formación contínua de Profesionales directivos, docentes, auxiliares, administrativos, y no docentes; que coadyuvan en el  desarrollo de su potencial con actitud innovadora, competitiva y comprometida en las dinámicas sociales que fortalecen su dinámica institucional  y de nuestro país.',
      },
      {
        image: imagen2,
        title: 'Misión',
        description:
          'Brindamos formación continua con servicio de calidad acorde al contexto e identidad de la Institución Educativa.',
      },
    ],
  }

  const recommendedServices = useSelector((state) => cursosDisponibles)

  return (
    <div className='objective-services'>
      <div className='objective-services__intro'>
        <h2 id='description'>Nuestros Objetivos</h2>
        <div class="underline"></div>
      </div>
      <div className='objective-services__content'>
        {recommendedServices?.objetivos !== null ? (
          recommendedServices?.objetivos?.map((objetivo) => (
            <ObjetivoCard objetivo={objetivo} key={Math.random()} />
          ))
        ) : (
          <CardSkeleton />
        )}
      </div>
    </div>
  )
}

export default Objetivo;
