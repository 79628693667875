import React from "react";
import Objetivo from '../../components/commons/Objetivos/Objetivo'
import Hero from '../../components/commons/Hero/Hero'
import headerImage from '../../components/commons/AboutUs/images/aboutUs.svg'
import './style.css'
const AboutUs = () => {
  return (
    <div>
      <Hero title={`Sobre Nosotros`} image={headerImage} />

      <div className='texto_nostros'>
        <p>
          Somos un equipo de educadores comprometidos, con calidad de servicio;
          orientada a la formación continua de los integrantes de la comunidad
          educativa y la mejora del desempeño en sus competencias profesionales
        </p>
      </div>
      <Objetivo />
    </div>
  )
}
  
  export default AboutUs;
  