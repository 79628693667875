import React from "react";
import SocialIcon from "../../elements/SocialIcon/SocialIcon";
import "./style.css";
import { AiFillFacebook,AiOutlineInstagram,AiOutlineTwitter,AiOutlineDiscord,AiOutlineYoutube } from "react-icons/ai";

const d = new Date();
const year = d.getFullYear();

const Footer = () => (
  <footer className="footer">
    <div className="container">
      <div className="footer__social">
        <SocialIcon
          link="#"
          icon={<AiFillFacebook className="icon"/>}
        />
        <SocialIcon
          link="#"
          icon={<AiOutlineInstagram className="icon"/>}
        />
        <SocialIcon
          link="#"
          icon={<AiOutlineYoutube className="icon"/>}
        />
      </div>
      <div className="footer__content">
        <p>© FYE {year} - Todos los derechos reservados</p>
      </div>
    </div>
  </footer>
);

export default Footer;
