import React from "react";
import { useSelector } from "react-redux";
import CardSkeleton from "../Skeletons/CardSkeleton";

import "./style.css";
import ServicesCard from "./ServicesCard";

import service1 from "./images/service1.svg"
import service2 from "./images/service2.svg"
import service3 from "./images/service3.svg"

const ServicesAll = () => {
  const serviciosDisponibles = {
    services: [
      //status, image, title, description, link, releaseDate
      {
        image: service1,
        title:
          'Asistencias técnicas de fortalecimiento y acompañamiento a la práctica pedagógica dirigido a educadores',
        description:
          'Modalidad presencial y virtual que les permite evaluar su desempeño, construir sus aprendizajes de manera interactiva y plantear estrategias de mejora centrado en un enfoque crítico reflexivo.',
        imageLeft: true,
      },
      {
        image: service2,
        title:
          'Asistencias técnicas de fortalecimiento y acompañamiento a la práctica de Gestión dirigido a personal Directivo',
        description:
          'Modalidad presencial y virtual que les permite evaluar su desempeño, afianzar su liderazgo transformacional orientado a la calidad educativa y el logro de los  objetivos estratégicos institucionales.',
        imageLeft: false,
      },
      {
        image: service3,
        title:
          'Asistencias técnicas orientadas al desarrollo de competencias parentales dirigido a padres de familia y cuidadores',
        description:
          'Modalidad presencial y virtual que les permita conocer las características , necesidades de sus hijos y fortalecer el vínculo familiar.',
        imageLeft: true,
      },
    ],
  }

  const recommendedServices = useSelector((state) => serviciosDisponibles)

  return (
    <section id='services'>
      <div className='services-all'>
        <div className='services-all__content'>
          <div className='services-all__intro'>
            <h2 id='description'>
              Que Servicios ofrecemos <br />
            </h2>
            <div class="underline"></div>
          </div>
          {recommendedServices?.services !== null ? (
            recommendedServices?.services?.map((service) => (
              <ServicesCard service={service} key={Math.random()} />
            ))
          ) : (
            <CardSkeleton />
          )}
        </div>
      </div>
    </section>
  )
}

export default ServicesAll;
