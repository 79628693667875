import React from "react";

const ObjetivoCard = (props) => {
  const { image, title, description, orientation_image } = props.objetivo

  return (
    <div className='objective-cards__service' id='objetive'>
      <div className='objective-cards__service-image'>
        <img src={image} alt='' style={{ height: '360px', width: '360px' }} />
      </div>
      <div>
        <div className='objective-cards__service-title'>
          <h3>{title}</h3>
        </div>
        <div className='objective-cards__service-description'>
          <p>{description}</p>
        </div>
      </div>
    </div>
  )
};

export default ObjetivoCard;
