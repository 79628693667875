import React from "react";
// import Button from "../../elements/Button/Button";
// import imagen1 from "./images/opcion1.png"

const ServicesCard = (props) => {
  const { image, title, description, imageLeft } = props.service

  let courseCardClasses = 'services-all__service'

  return (
    <div className={courseCardClasses} id='services'>
      <div
        className={`services-all__service ${
          imageLeft ? 'image-left' : 'image-right'
        }`}
        id='services'
      >
        <div className='services-all__service-image'>
          <img src={image} alt={title} />
        </div>
        <div className='services-all__service-group-description'>
          <div className='services-all__service-title'>
            <h3>{title}</h3>
          </div>
          <div className='services-all__service-description'>
            <p>{description}</p>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ServicesCard;
