import React, { useEffect, useState } from "react";
import logo from "./logo_negro.webp";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logOutFirebase } from "../../../store/actions/auth"; 
import "./style.css";
import { FaBars, FaTimes } from "react-icons/fa";

const goToHome = () => {
  window.location.href = "/";
};

const showMenu = () => {
  let navMenu = document.getElementById("navMenu");
  navMenu.classList.toggle("show");
};


const scrollToServices = () => {
  const servicesSection = document.getElementById('services');
  showMenu()
  if (!servicesSection) {
    // Si servicesSection es null, redirigir a la página principal
    window.location.href = '/';
    
    // Realizar el scroll después de redirigir a la página principal
    setTimeout(() => {
      const servicesSection = document.getElementById('services');
      if (servicesSection) {
        servicesSection.scrollIntoView({ behavior: 'smooth' });
      }
    }, 500); // Retrasar la ejecución en 500 milisegundos
  } else {
    // Si servicesSection está presente, realizar el scroll
    servicesSection.scrollIntoView({ behavior: 'smooth' });
  }
};

const Nav = (props) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth);
  const [currentLink, setCurrentLink] = useState("/login");
  const [menuOpen, setMenuOpen] = useState(false);

  const userLogout = () => {
    dispatch(logOutFirebase());
  };

  useEffect(() => {
    if (currentUser?.displayName) {
      setCurrentLink("/admin");
    }
  }, [currentUser]);

  const menulist = [
    { name: "Servicios", type: "Button", link: "/servicios" },
    { name: "Nosotros", type: "Link", link: "/nosotros" },
    { name: "Testimonios", type: "Link", link: "/testimonios" },
    { name: "Calendario", type: "Link", link: "/calendario" },
  ];

  return (
    <nav className='nav'>
      <div className='container'>
        <div className='nav__content'>
          <div className='nav__top'>
            <div className='nav__logo' id='burger'>
              {/* <img src={logo} alt="" onClick={goToHome} /> */}
              <img
                src={logo}
                alt=''
                onClick={goToHome}
                style={{ borderRadius: '50%' }}
              />
              <span>FYE Didactica Educativa</span>
            </div>
            <div className='nav__links'>
              <ul>
                {menulist.map((item, index) => {
                  if (item.type === 'Link') {
                    return (
                      <li key={index}>
                        <Link to={item.link}>{item.name}</Link>
                      </li>
                    )
                  } else {
                    return (
                      <li key={index}>
                        <a onClick={scrollToServices}>{item.name}</a>
                      </li>
                    )
                  }
                })}
              </ul>
            </div>
            <div className='nav__button'>
              <button className='btn' onClick={showMenu}>
              <FaBars size={30} />
              </button>
            </div>
          </div>
          <div className='nav__bottom'>
            <ul className='nav__menu fadeIn' id='navMenu'>
              <button id='navClose' onClick={showMenu}>
                X
              </button>

              {menulist.map((item, index) => {
                if (item.type === 'Link') {
                  return (
                    <li key={index}>
                      <Link to={item.link} onClick={showMenu}>
                        {item.name}
                      </Link>
                    </li>
                  )
                } else {
                  return (
                    <li key={index}>
                      <a onClick={scrollToServices}>{item.name}</a>
                    </li>
                  )
                }
              })}
            </ul>
          </div>
        </div>
      </div>

      {/* Menú desplegable */}
      <div className={`nav__menu ${menuOpen ? "show" : ""}`}>
        <button className="nav__close" onClick={() => setMenuOpen(false)}>
          <FaTimes size={30} />
        </button>
        <ul>
          {menulist.map((item, index) => (
            <li key={index}>
              <Link to={item.link} onClick={() => setMenuOpen(false)}>{item.name}</Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  )
};

export default Nav;

