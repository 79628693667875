import React, { Suspense } from 'react'
import Hero from '../../components/commons/Hero/Hero'
import calendarImage from './calendar-img.svg'
import './style.css'
import evenst_list from './events_list.js'
import Skeleton from 'react-loading-skeleton'

const Cardevents = React.lazy(() =>
  import('../../components/commons/cards/cards_events')
)

const Calendar = () => {
  return (
    <div>
      <Hero title={`Calendario de actividades`} image={calendarImage} />
      <div className='calendar-container'>
        <Suspense
          fallback={
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Skeleton count={3} width={400} height={300} />
            </div>
          }
        >
          <div className='calendar-grid'>
            {evenst_list.map((event, key) => (
              <Cardevents key={key} {...event} />
            ))}
          </div>
        </Suspense>
      </div>
    </div>
  )
}

export default Calendar
